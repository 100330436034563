<template>
  <div class="home" v-if="$page && $beet.isReady">
    <div
      class="img"
      :style="`background-image: url('${$page.header.image.lg}');`"
    >
      <div>
        <p class="img__info">{{ $page.header.title }}</p>
        <p class="img__text">{{ $page.header.subtitle }}</p>
        <div class="img__flex -pointer" @click="scrollMeTo('themes')">
          <img
            v-for="(theme, index) in Object.values($beet.themes).splice(0, 3)"
            :key="`headerTheme-${index}`"
            :src="theme.image.thumb"
            class="img__tumbnail"
            :alt="theme.name"
          />
          <p class="img__button-text">
            {{ $page.header.linkText }} <Icon id="ArrowDiagonal" />
          </p>
        </div>
      </div>
      <div>
        <p class="img__topic">{{ $page.discussion.title }}</p>
        <div class="img__flex -start">
          <div>
            <h1 class="img__title">{{ $page.discussion.subtitle }}</h1>
            <p class="img__subtitle">{{ $beet.options.menu.dates }}</p>
          </div>
          <div class="img__flex -bottom -mt">
            <button
              class="img__button"
              @click="$router.push({ name: 'program' })"
            >
              <Icon id="ArrowDiagonal" />
            </button>
            <p class="img__label">{{ $page.discussion.explore }}</p>
          </div>
        </div>
      </div>
    </div>

    <div class="home__wrap">
      <section class="home-content">
        <div class="home-content__topics">
          <div class="home-content__topic">{{ $page.urgency.title }}</div>
          <div class="home-content__topic" v-if="mq.mdPlus">
            {{ $page.goals.title }}
          </div>
        </div>
        <div
            class="home-content__img"
            :style="`background-image: url('${$page.urgency.image.lg}');`"
        ></div>
        <div class="home-content__flex">
          <div class="home-content__content -mr">
            <h2 class="home-content__title">{{ $page.urgency.subtitle }}</h2>
            <p>{{ $page.urgency.description }}</p>
            <div class="home-content__flex -bottom">
              <button
                  class="img__button"
                  @click="$router.push({ name: 'program' })"
              >
                <Icon id="ArrowDiagonal" />
              </button>
              <p class="img__label">{{ $page.urgency.explore }}</p>
            </div>
          </div>
          <div class="home-content__content -mt">
            <div class="home-content__topics" v-if="!mq.mdPlus">
              <div class="home-content__topic">{{ $page.goals.title }}</div>
            </div>
            <div
                class="card"
                v-for="(card, index) in $page.goals.items"
                :key="`goal-${index}`"
            >
              <div
                  class="card__icon"
                  :style="`background-image: url('${card.image.original}');`"
              ></div>
              <div class="card__content">
                <h2 class="card__title">{{ card.title }}</h2>
                <p>{{ card.description }}</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="themes__header" ref="themes">
        <div>
          <div class="themes__header-left">
            <div class="themes__label">{{ $t("themes") }}</div>
            <div class="themes__count">
              {{ Object.keys($beet.themes).length }}
            </div>
          </div>
          <div class="themes__lines">
            <div
                v-for="(theme, index, key) in $beet.themes"
                :key="`line-${index}`"
                class="themes__line"
                :class="{ '-active': themeIndex === key }"
            ></div>
          </div>
        </div>
        <div class="team__arrows" v-if="mq.mdPlus">
          <button @click="slideLeftThemes" :disabled="themesLeftDisabled">
            <icon
                id="Arrow"
                class="team__arrow-left team__arrow"
                :class="{ '-disabled': themesLeftDisabled }"
            /></button
          ><button @click="slideRightThemes" :disabled="themesRightDisabled">
          <icon
              id="Arrow"
              class="team__arrow"
              :class="{ '-disabled': themesRightDisabled }"
          />
        </button>
        </div>
      </section>
      <swiper
          :slides-per-view="mq.mdPlus ? 3 : 1"
          :space-between="16"
          @swiper="onSwiperThemes"
          class="themes"
      >
        <swiper-slide
            class="themes__card"
            v-for="(theme, index) in $beet.themes"
            :key="`theme-${index}`"
        >
          <img :src="theme.image.lg" :alt="theme.name" class="theme-card__img" />
          <button class="theme-card__button" @click="$router.push({name: 'program'})">
            <Icon id="ArrowDiagonal" />
          </button>
          <h2 class="theme-card__title">{{ theme.name }}</h2>
          <p v-html="theme.description"/>
        </swiper-slide>
      </swiper>

      <div class="team">
        <h2 class="team__title">
        <span class="team__title-text">{{ $page.team.title }}</span
        ><span class="team__arrows" v-if="mq.mdPlus"
        ><button @click="slideLeft" :disabled="leftDisabled">
            <icon
                id="Arrow"
                class="team__arrow-left team__arrow"
                :class="{ '-disabled': leftDisabled }"
            /></button
        ><button @click="slideRight" :disabled="rightDisabled">
            <icon
                id="Arrow"
                class="team__arrow"
                :class="{ '-disabled': rightDisabled }"
            /></button
        ></span>
        </h2>
        <swiper
            class="team__cards"
            :slides-per-view="mq.mdPlus ? 5 : 2"
            :space-between="16"
            @swiper="onSwiper"
        >
          <swiper-slide
              class="team__card"
              v-for="(card, index) in $beet.team"
              :key="`team-${index}`"
              @click="openedPopup = card"
          >
            <div class="team-card__single">
              <img
                  v-if="card.photo"
                  :src="card.photo.lg"
                  :alt="card.name"
                  class="team-card__img"
              />
              <button class="team-card__button">
                <Icon id="ArrowDiagonal" />
              </button>
            </div>
            <h3 class="team-card__name">{{ card.name }}</h3>
            <p>{{ card.title }}</p>
          </swiper-slide>
        </swiper>
      </div>
      <div class="sponsor">
        <h2 class="sponsor__topic">{{ $page.sponsor.title }}</h2>
        <div class="sponsor__content">
          <div class="sponsor__text">
            <h3 class="sponsor__title">{{ $page.sponsor.subtitle }}</h3>
            <div
                v-if="!mq.mdPlus"
                class="sponsor__img"
                :style="`background-image: url('${$page.sponsor.image.lg}');`"
            ></div>

            <p class="raw-html">
              {{
                isSponsorshipAllSeen || $page.sponsor.description.length <= 500
                    ? $page.sponsor.description
                    : $page.sponsor.description.substr(0, 500) + " ..."
              }}

              <a v-if="isSponsorshipAllSeen || $page.sponsor.description.length <= 500" href="https://assets.boitebeet.com/icisr/16/ICISR-Sponsorship-program_2024_EN_final.pdf" target="_blank">
                Sponsorship Program
              </a>
            </p>
            <button
                class="sponsor__more"
                v-if="!isSponsorshipAllSeen && $page.sponsor.description.length > 500"
                @click="isSponsorshipAllSeen = true"
            >
              {{ $t("buttons.more") }} +
            </button>
            <button
                class="sponsor__more"
                v-else-if="$page.sponsor.description.length > 500"
                @click="isSponsorshipAllSeen = false"
            >
              {{ $t("buttons.less") }} -
            </button>
            <div class="sponsor__flex -button" @click="openTicket">
              <button class="sponsor__button">
                <Icon id="ArrowDiagonal" />
              </button>
              <p class="sponsor__button-text">{{ $page.sponsor.offer }}</p>
            </div>
          </div>
          <div
              v-if="mq.mdPlus"
              class="sponsor__img"
              :style="`background-image: url('${$page.sponsor.image.lg}');`"
          ></div>
        </div>
        <h2 class="sponsor__topic -mt">{{ $page.sponsor.title }}</h2>
        <div class="sponsor__carousel">
          <div class="sponsor__icons -primary">
            <a
                class="sponsor__link"
                v-for="(sponsor, index) in [...Object.values($beet.sponsors), ...Object.values($beet.sponsors)]"
                :key="`sponsor-${index}`"
                :href="sponsor.link"
                target="_blank"
            >
              <img
                  class="sponsor__icon"
                  :src="sponsor.logo.thumb || sponsor.logo.sm || sponsor.logo.original"
                  :alt="sponsor.name"
              />
            </a>
          </div>
        </div>
      </div>

      <div class="contact-section">
        <ContactUs />
      </div>
    </div>

    <Footer />
  </div>

  <transition name="appear">
  <team-popup
    :title="$page.team.title"
    :section="openedPopup"
    v-if="openedPopup !== null"
    @close="openedPopup = null"
  />
  </transition>
</template>

<script>
import ContactUs from "@/components/ContactUs";
import Footer from "@/components/Footer";
import TeamPopup from "@/components/TeamPopup.vue";
import { Swiper, SwiperSlide } from "swiper/vue";

export default {
  name: "home",
  beetPage: "home",
  inject: ["mq"],
  components: {Footer, ContactUs, TeamPopup, Swiper, SwiperSlide },
  data() {
    return {
      swiper: null,
      leftDisabled: true,
      rightDisabled: false,
      swiperThemes: {},
      themesLeftDisabled: true,
      themesRightDisabled: false,
      isSponsorshipAllSeen: false,
      openedPopup: null,
    };
  },
  computed: {
    themeIndex() {
      return this.swiperThemes.activeIndex || 0
    }
  },
  methods: {
    openTicket() {
      window.open(this.$beet.options.menu.ticketsLink, '_blank')
    },
    scrollMeTo(ref) {
      window.scrollTo({
        top: this.$refs[ref].offsetTop - 200,
        left: 0,
        behavior: "smooth",
      });
    },
    onSwiper(swiper) {
      this.swiper = swiper;
    },
    slideRight() {
      this.swiper.slideNext();
      this.resetDisabled();
      if (this.swiper.isEnd) {
        this.rightDisabled = true;
      }
    },
    slideLeft() {
      this.swiper.slidePrev();
      this.resetDisabled();
      if (this.swiper.isBeginning) {
        this.leftDisabled = true;
      }
    },
    resetDisabled() {
      this.leftDisabled = false;
      this.rightDisabled = false;
    },
    onSwiperThemes(swiper) {
      this.swiperThemes = swiper;
    },
    slideRightThemes() {
      this.swiperThemes.slideNext();
      this.resetDisabledThemes();
      if (this.swiperThemes.isEnd) {
        this.themesRightDisabled = true;
      }
    },
    slideLeftThemes() {
      this.swiperThemes.slidePrev();
      this.resetDisabledThemes();
      if (this.swiperThemes.isBeginning) {
        this.themesLeftDisabled = true;
      }
    },
    resetDisabledThemes() {
      this.themesLeftDisabled = false;
      this.themesRightDisabled = false;
    },
  }
}
</script>
