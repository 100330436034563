<template>
  <div class="program" v-if="$page && $beet.isReady">
    <div
      class="header-img"
      :style="`background-image: url('${$page.header.image.lg}');`"
    >
      <div class="header-img__topic">{{ $page.header.title }}</div>
      <div class="header-img__heading">{{ $page.header.subtitle }}</div>
      <div class="header-img__subtitle">{{ $beet.options.menu.dates }}</div>
      <!-- TODO retirer avant la mise en ligne -->
      <button v-if="isBetaProg" @click="isTemporaryPage = !isTemporaryPage">Full program coming soon</button>
    </div>
    <div class="header-img__buttons-sticky">
    <swiper class="header-img__buttons" v-if="!isTemporaryPage" :space-between="10" slides-per-view="auto" :free-mode="true" :height="50">
      <swiper-slide
        v-for="(date, index) in dates"
        :key="`button-${index}`"
        @click="currentDate = date"
        class="-button"
        :class="{ '-active': currentDate === date }"
      >
        {{ formatDate(date) }}
    </swiper-slide>
  </swiper>
</div>
    <div class="home__wrap">
      <div class="section__flex -top">
        <div class="section__frame">
          <div class="section -temporary" v-if="isTemporaryPage">
            <p class="section__topic">{{ $page.soon.title }}</p>
            <p class="section__title -temporary">{{ $page.soon.subtitle }}</p>
            <raw-html
                :html="$page.soon.description"
                class="section__content -temporary"
            />

            <img :src="$page.soon.image?.lg || $page.soon.image?.original || $page.soon.image" style="width: 80%; margin:auto; height: auto; object-fit: contain;"/>

            <router-link :to="{ name: 'submission' }" class="section__flex-button">
              <button class="section__flex-icon">
                <Icon id="ArrowDiagonal" />
              </button>
              <p class="section__flex-label">{{ $page.soon.info }}</p>
            </router-link>
          </div>

          <template v-else>
            <div class="section__empty" v-if="selectedSections.length === 0">{{ $t('empty') }}</div>
            <div
                v-for="(section, index) in selectedSections"
                :key="index"
                class="section"
                :class="{ '-background': section.type === 3 }"
            >
              <p class="section__topic">
                {{ section.time }} - {{ section.location }}
              </p>
              <p class="section__title">{{ section.title }}</p>
              <p v-if="section.description" class="section__content">
                {{ section.description.length > 300 ? section.description.substring(0, 300) + ' ...' : section.description }}
              </p>
              <div class="section__flex -between">
                <div>
                  <template v-if="section.type !== 3">
                    <div
                        v-for="(presenter, indexPresenter) in section.presenters"
                        :key="`presenter-${indexPresenter}`"
                        class="section__tag"
                    >
                      <Icon id="Speaker" class="section__icon" />
                      {{ $beet.presenters[presenter].name }} |
                      {{ $beet.presenters[presenter].title }} |
                      {{ $beet.presenters[presenter].company }}
                    </div>
                  </template>
                  <div
                      v-if="(section.type || section.themes.length) && section.type !== 3"
                      class="section__flex -wrap"
                  >
                    <div v-if="section.type" class="section__tag">
                      <Icon id="Type" class="section__icon" />
                      {{ $beet.types[section.type].name }}
                    </div>
                    <div
                        v-for="(theme, indexThemes) in section.themes"
                        :key="`theme-${indexThemes}`"
                        class="section__tag"
                    >
                      <Icon id="Theme" class="section__icon" />
                      {{ $beet.themes[theme].name.length > 50 ? $beet.themes[theme].name.substring(0, 50) + ' ...' : $beet.themes[theme].name }}
                    </div>
                  </div>
                </div>
                <button
                    @click="openedPopup = index"
                    v-if="section.description"
                    class="section__button"
                >
                  <Icon id="ArrowDiagonal" />
                </button>
                <transition name="appear">
                <Popup
                    :section="section"
                    v-if="openedPopup === index"
                    @close="openedPopup = null"
                />
                </transition>
              </div>
            </div>
          </template>
        </div>
        <div class="filters" v-if="!isTemporaryPage">
          <p class="filters__title">{{ $t('filters') }}</p>
          <div class="filters__button" @click="selectType = !selectType">
            <div class="section__flex">
              <Icon id="Type" class="filters__icon"/>
              <span class="filters__label">{{ $t('select.type') }}</span>
            </div>
            <Icon id="Accordeon" class="filters__icon" :class="{'-reverse': selectType}"/>
          </div>
          <DropDown :is-open="selectType">
            <div class="filters__options">
              <label class="filters__option" v-for="(type, index) in $beet.types" :key="`type-${index}`">
                <input type="checkbox" @click="changeCheckbox('type', type.id)" :checked="typeFilters.includes(type.id)"/>
                <p class="filters__option-text">
                  <span :class="{'-active': typeFilters.includes(type.id)}" class="filters__checkbox"></span><span class="filters__option-label">{{ type.name }}</span>
                </p>
              </label>
            </div>
          </DropDown>
          <div class="filters__button" @click="selectTheme = !selectTheme">
            <div class="section__flex">
              <Icon id="Theme" class="filters__icon"/>
              <span class="filters__label">{{ $t('select.theme') }}</span>
            </div>
            <Icon id="Accordeon" class="filters__icon" :class="{'-reverse': selectTheme}"/>
          </div>
          <DropDown :is-open="selectTheme">
            <div class="filters__options">
              <label class="filters__option" v-for="(theme, index) in $beet.themes" :key="`theme-${index}`">
                <input type="checkbox" @click="changeCheckbox('theme', theme.id)"
                       :checked="themeFilters.includes(theme.id)"/>
                <p class="filters__option-text"><span :class="{'-active': themeFilters.includes(theme.id)}" class="filters__checkbox"></span><span class="filters__option-label">{{ theme.name }}</span></p>
              </label>
            </div>
          </DropDown>
          <p v-if="typeFilters.length || themeFilters.length" class="filters__reset" @click="resetFilters">{{ $t('reset') }}
            <Icon id="Reset"/>
          </p>
        </div>
      </div>
      <div>
        <ContactUs />
      </div>
    </div>



    <Footer />
  </div>
</template>

<script>
import ContactUs from "@/components/ContactUs";
import Footer from "@/components/Footer";
import Popup from "@/components/Popup";
import { Swiper, SwiperSlide } from "swiper/vue";

export default {
  name: "Program",
  beetPage: "program",
  components: { Footer, ContactUs, Popup, Swiper, SwiperSlide },
  data() {
    return {
      currentDate: "05/14/2024",
      isTemporaryPage: true,
      selectType: false,
      selectTheme: false,
      typeFilters: [],
      themeFilters: [],
      openedPopup: null,
      dates: [
        "05/14/2024",
        "05/15/2024",
        "05/16/2024",
        "05/17/2024"
      ]
    };
  },
  computed: {
    isBetaProg() {
      return process.env.VUE_APP_PROG_BETA
    },
    selectedSections() {
      if (this.themeFilters.length === 0 && this.typeFilters.length === 0) {
        return Object.values(this.$beet.conferences).filter(
          (section) =>
            new Date(section.date).getDate() === new Date(this.currentDate).getDate()
        );
      } else {
        return Object.values(this.$beet.conferences).filter(
          (section) =>
          {
           return (
              (this.typeFilters.includes(section.type) || !this.typeFilters.length) &&
              (this.themeFilters.some(theme => section.themes.includes(theme)) || !this.themeFilters.length)
            ) &&
            new Date(section.date).getDate() === new Date(this.currentDate).getDate()
          }
        );
      }
    },
  },
  methods: {
    changeCheckbox(type, value) {
      if (this[`${type}Filters`].includes(value)) {
        const index = this[`${type}Filters`].indexOf(value);
        this[`${type}Filters`].splice(index, 1);
      } else {
        this[`${type}Filters`].push(value);
      }
    },
    resetFilters() {
      this.typeFilters = [];
      this.themeFilters = [];
    },
    formatDate(date) {
      return new Date(date).toLocaleDateString("EN-CA", {
        weekday: "long",
        day: "numeric",
        month: "long",
      });
    },
  },
};
</script>
