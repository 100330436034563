<template>
    <div class="popup__backdrop" @click="$emit('close')">
      <div class="popup" @click.stop>
        <div class="popup__header">
          <h2>{{ title }}</h2>
          <button class="popup__button" @click="$emit('close')"><Icon id="Close" /></button>
        </div>
        <div class="popup__flex -img">
            <img class="popup__img -team" :src="section.photo.lg" :alt="section.name">
            <div>
                <h3 class="popup__title">{{ section.name }}</h3>
                <div class="popup__tag">
                    <Icon id="Type" class="popup__icon"/>
                    <span>{{ section.title }}</span>
                </div>        
            </div>
        </div>

        <raw-html :html="section.description"/>  
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "TeamPopup",
    props: {
      section: Object,
      title: String
    }
  }
  </script>
  
  <style scoped>
  
  </style>