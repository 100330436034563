<template>
  <div class="submission" v-if="$page && $beet.isReady">
    <div class="header-img" :style="`background-image: url('${$page.header.image.lg}');`">
      <div class="header-img__topic">{{ $page.header.title }}</div>
      <div class="header-img__heading">{{ $page.header.subtitle }}</div>
      <div class="header-img__subtitle">{{ $beet.options.menu.dates }}</div>
    </div>

    <div class="home__wrap">
      <div class="submission__content">
        <h2 class="submission__topic">{{ $page.submit.title }}</h2>
        <div class="submission__flex">
          <div class="submission__section">
            <img v-if="!mq.mdPlus" :src="$page.submit.image.lg" alt="key dates" class="submission__img">
            <h2 class="submission__title">{{ $page.submit.subtitle }}</h2>
            <raw-html class="submission__description" :html="$page.submit.description"/>
            <div class="submission__frame" v-if="!mq.mdPlus">
              <raw-html class="submission__margin" :html="$page.submit.keyDates"/>
            </div>
            <div class="submission__flex -button">
              <button class="submission__button" @click="scrollToBottom">
                <Icon id="ArrowDiagonal"/>
              </button>
              <p>{{ $page.submit.linkText }}</p>
            </div>
          </div>
          <div class="submission__section" v-if="mq.mdPlus">
            <img :src="$page.submit.image.lg" alt="key dates" class="submission__img">
            <div class="submission__frame">
              <raw-html class="submission__margin" :html="$page.submit.keyDates"/>
            </div>
          </div>
        </div>

        <div class="team -no-margin">
          <h2 class="team__title">
        <span class="team__title-text">{{ $page.committee.title }}</span
        ><span class="team__arrows" v-if="mq.mdPlus && Object.keys($beet.scientists).length > 5"
          ><button @click="slideLeft" :disabled="leftDisabled">
            <icon
                id="Arrow"
                class="team__arrow-left team__arrow"
                :class="{ '-disabled': leftDisabled }"
            /></button
          ><button @click="slideRight" :disabled="rightDisabled">
            <icon
                id="Arrow"
                class="team__arrow"
                :class="{ '-disabled': rightDisabled }"
            /></button
          ></span>
          </h2>
          <swiper
              class="team__cards"
              :slides-per-view="mq.mdPlus ? 5 : 2"
              :space-between="16"
              @swiper="onSwiper"
          >
            <swiper-slide
                class="team__card"
                v-for="(card, index) in $beet.scientists"
                :key="`team-${index}`"
                @click="openedPopup = card"
            >
              <div class="team-card__single">
                <img
                    v-if="card.photo"
                    :src="card.photo.lg"
                    :alt="card.name"
                    class="team-card__img"
                />
                <button class="team-card__button">
                  <Icon id="ArrowDiagonal" />
                </button>
              </div>
              <h3 class="team-card__name">{{ card.name }}</h3>
              <p>{{ card.title }}</p>
            </swiper-slide>
          </swiper>
        </div>

        <div class="contact__section">
          <ContactUs isAbstract/>
        </div>
    </div>
      <div ref="contact"></div>
    </div>
    <Footer/>
  </div>
  <transition name="appear">
  <team-popup
    :title="$page.committee.title"
    :section="openedPopup"
    v-if="openedPopup !== null"
    @close="openedPopup = null"
  />
  </transition>
</template>

<script>
import ContactUs from "@/components/ContactUs";
import Footer from "@/components/Footer";
import TeamPopup from "@/components/TeamPopup.vue";
import { Swiper, SwiperSlide } from "swiper/vue";
export default {
  name: "submission",
  beetPage: "submission",
  inject: ['mq'],
  components: {Footer, ContactUs, TeamPopup, Swiper, SwiperSlide},
  data() {
    return {
      swiper: null,
      leftDisabled: true,
      rightDisabled: false,
      openedPopup: null
    };
  },
  methods: {
    scrollToBottom () {
      window.scrollTo({ top: this.$refs["contact"].offsetTop - 650, behavior: 'smooth' });
    },
    onSwiper(swiper) {
      this.swiper = swiper;
    },
    slideRight() {
      this.swiper.slideNext();
      this.resetDisabled();
      if (this.swiper.isEnd) {
        this.rightDisabled = true;
      }
    },
    slideLeft() {
      this.swiper.slidePrev();
      this.resetDisabled();
      if (this.swiper.isBeginning) {
        this.leftDisabled = true;
      }
    },
    resetDisabled() {
      this.leftDisabled = false;
      this.rightDisabled = false;
    },
  }
};
</script>
