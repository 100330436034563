<template>
  <div class="info" v-if="$page && $beet.isReady">
    <div class="header-img -no-pb" :style="`background-image: url('${$page.header.image.lg}');`">
      <div class="header-img__topic">{{ $page.header.subtitle }}</div>

      <div class="header-img__flex">
        <div class="header-img__info">
          <div class="header-img__heading">{{ $page.header.title }}</div>
          <div class="header-img__subtitle">{{ $beet.options.menu.dates }}</div>
        </div>
      </div>
    </div>

    <div class="home__wrap">

      <div class="venue" ref="venue" >
        <div class="venue__topics">
          <div class="venue__topic">{{ $page.options.subtitle }}</div>
        </div>

        <div class="venue__flex -m-top">
          <div class="venue__element ">
            <div class="venue__sticky">
              <h2 class="venue__title">{{ $page.options.title }}</h2>

              <raw-html class="submission__description" :html="$page.options.description"/>

              <a href="https://commerceweb.ulaval.ca/shopping/crib/event/2024_icisr_international_conference_on_iron_sulfide_reactions_in_concrete/Detail.view" target="_blank" class="venue__flex -button">
                <button class="venue__button">
                  <Icon id="ArrowDiagonal"/>
                </button>
                <p class="venue__button-label">GET MORE INFO AND BUY TICKETS</p>
              </a>
            </div>

          </div>

          <div class="venue__element -mt" ref="transport">
            <div class="venue__card" v-for="(card, index) in $page.options.items" :key="`transport-${index}`">
              <h3 class="venue__card-title">{{ card.title }}</h3>
              <raw-html class="venue__card-content" :html="card.description"/>
              <div class="venue__card-content--price">
                <span>{{card.price}} $</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="venue -mt" ref="accomodations">
        <h2 class="accomodations__topic">{{ $page.special.subtitle }}</h2>
        <div class="venue__flex -reverse">
          <div class="venue__element -mt">
            <h3 class="venue__title">{{ $page.special.title }}</h3>
            <raw-html class="venue__margin submission__description" :html="$page.special.description"/>
            <a :href="$page.special.link" target="_blank" class="venue__flex -button">
              <button class="venue__button">
                <Icon id="ArrowDiagonal"/>
              </button>
              <p>{{ $page.special.linkText }}</p>
            </a>
          </div>
          <div class="venue__element">
            <img :src="$page.special.image.lg" :alt="$page.special.subtitle" class="accomodations__img">
          </div>
        </div>
      </div>

      <div class="venue -mt" ref="faq">
        <h2 class="faq__title">{{ $page.terms.title }}</h2>
        <div class="faq__card" @click="open(index)" v-for="(term, index) in $page.terms.items" :key="`terms-${index}`">
          <h3 class="faq__question"><span>{{ term.title }}</span><Icon id="Accordeon" :class="{'-opened': opened === index}" class="faq__icon"/></h3>
          <DropDown :is-open="opened === index">
            <div class="faq__answer">
              <raw-html :html="term.description"/>
            </div>
          </DropDown>
        </div>
      </div>

      <div class="contact-section">
        <ContactUs/>
      </div>
    </div>


    <Footer/>
  </div>
</template>

<script>
import ContactUs from "@/components/ContactUs.vue";
import RawHtml from "@/components/global/RawHtml.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: 'Prices',
  components: {Footer, RawHtml, ContactUs},
  beetPage: 'pricing',
  data () {
    return {
      opened: null,
    };
  },
  methods: {
    open (index) {
      if (this.opened === index) {
        this.opened = null;
      } else {
        this.opened = index;
      }
    }
  }
}
</script>
