<template>
  <div class="contact" id="contact">
    <p class="contact__topic">{{ $beet.options.contact.subtitle }}</p>

    <div class="contact__flex">
      <div class="contact__section">
        <h2 class="contact__title">{{ $beet.options.contact.title }}</h2>
        <raw-html tag="p" :html="$beet.options.contact.description" class="contact__text"/>
        <div class="contact__flex -button" v-if="false">
          <button  class="contact__button" @click="$router.push({ name: 'info' })">
            <Icon id="ArrowDiagonal"/>
          </button>
          <p>{{ $beet.options.contact.explore }}</p>
        </div>
      </div>

      <div class="contact__section">
        <form @submit.prevent="sendForm" v-if="false">
          <div class="form__flex">
            <select class="form__input" v-model="form.subject">
              <option disabled :value="null">{{ $t('form.subject') }}</option>
              <option v-for="(subject, index) in subjects2" :key="`subject-${index}`" :value="subject.key">{{ subject.label }}</option>
            </select>
            <input type="text" class="form__input" v-model="form.name" :placeholder="$t('form.name')"/>
          </div>

          <div class="form__flex">
            <input type="text" class="form__input" v-model="form.email" :placeholder="$t('form.email')"/>
            <input type="text" class="form__input" v-model="form.company" :placeholder="$t('form.company')">
          </div>

          <textarea class="form__input" v-model="form.content" rows="6" :placeholder="$t('form.message')"></textarea>

          <div class="form__flex -top">
            <div class="form__element" v-if="abstract">

              <div class="form__files" v-if="!form.file || !form.image">
                <input type="file" id="files" class="form__file" ref="files" @change="addFile" multiple>
                <label for="files" class="form__label">{{ $beet.options.contact.uploadMsg }}<Icon id="Upload" class="form__icon"/></label>
              </div>



              <div class="form__row" v-if="form.file">
                {{ form.file.name.slice(0, 25) }}{{ form.file.name.length > 25 ? '...' : ''}}
                <Icon id="Close" class="form__icon" @click="removeFile()"/>
              </div>

              <div class="form__row" v-if="form.image">
                {{ form.image.name.slice(0, 25) }}{{ form.image.name.length > 25 ? '...' : ''}}
                <Icon id="Close" class="form__icon" @click="removeImage()"/>
              </div>

              <div class="form__row">
                <div v-if="!form.file || !form.image" class="form__instructions">
                  Your abstract should not be over 20 lines and must be in <b>.pdf format</b>. Also upload the author’s picture in <b>.jpg format</b>.
                </div>
              </div>

            </div>
            <button class="form__button" type="submit">{{ $beet.options.contact.sendBtn }} <Icon id="ArrowDiagonal"/></button>
          </div>
          <div v-if="error" class="form__error"> {{ error }} </div>
          <transition name="fade-fast" appear mode="out-in">
            <div class="form__confirm" v-if="sent" :key="sent">{{ $beet.options.contact.sentResponse }}</div>
            <div v-else />
          </transition>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import Http from "@/extensions/Http";
export default {
  name: "ContactUs",
  props: {
    isAbstract: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      subjects2: [
        {key: 'abstract', label: 'Submit an abstract'},
        {key: 'extended', label: 'Submit an extended abstract'},
        {key: 'general', label: 'General inquiry'},
      ],
      subjects: [
        'Submit an abstract',
        'Submit an extended abstract',
        'General inquiry'
      ],
      error: null,
      sent: false,
      form: {
        subject: this.isAbstract ? 'abstract' : null,
        name: null,
        email: null,
        company: null,
        content: null,
        file: null,
        image: null
      }
    };
  },
  computed: {
    abstract() {
      return this.form.subject === 'abstract'
    }
  },
  watch: {
    form: {
      deep: true,
      handler: function() {
        this.error = null
      }
    }
  },
  methods: {
    addFile () {
      Object.values(this.$refs['files'].files).forEach(file => {
        if(file.type.includes('pdf')) this.form.file = file
        else if (file.type.includes('image')) this.form.image = file
      })
    },
    removeFile() {
      this.form.file = null
    },
    removeImage() {
      this.form.image = null
    },
    sendForm() {
      const formData = new FormData();
      Object.entries(this.form).forEach((entry) => {
        if(entry[1]) formData.append(entry[0], entry[1])
      })

      Http.post('/contact', formData).then(() => {
        this. form = {
          subject: this.isAbstract ? 'abstract' : null,
          name: null,
          email: null,
          company: null,
          content: null,
          file: null,
          image: null
        }
        this.sent = true

        setTimeout(() => {
          this.sent = false
        }, 10000)
      }).catch(({response}) => {
        this.error = response.data.message
      })
    },
  }
}
</script>
