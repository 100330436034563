<template>
  <SiteNav @scrollBottom="scrollBottom"/>
  <template v-if="ready">
    <router-view v-slot="{ Component }" >
      <transition name="fade-fast" mode="out-in" appear>
        <div :key="$route.name">
          <component :is="Component" />
        </div>
      </transition>
    </router-view>
  </template>
</template>

<script>
import SiteNav from "@/components/SiteNav";
export default {
  name: 'App',
  components: { SiteNav },
  watch: {
    '$i18n.locale': {
      handler: function(to) {
        this.$beet.fetchCoreData(to)
      },
      immediate: true
    }
  },
  computed: {
    ready() {
      return this.$beet.isReady
    }
  },
  methods: {
    scrollBottom() {
      const el = document.getElementById('contact')
      el.scrollIntoView({behavior: 'smooth'})
    }
  }
}
</script>
