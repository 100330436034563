import {createRouter, createWebHistory} from 'vue-router'
import {Home, Program, NotFound, Info, Submission, Tickets, Prices} from '@/views'

const routes = [
    {
        path: '/',
        name: 'home',
        component: Home
    },
    {
        path: '/program',
        name: 'program',
        component: Program
    },
    {
        path: '/info',
        name: 'info',
        component: Info
    },
    {
        path: '/submission',
        name: 'submission',
        component: Submission
    },
    {
        path: '/tickets',
        name: 'tickets',
        component: Tickets
    },
    {
        path: '/prices',
        name: 'prices',
        component: Prices
    },
    {
        path: '/404',
        name: 'not-found',
        component: NotFound
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'catch-all',
        component: NotFound
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    linkActiveClass: '-active',
    linkExactActiveClass: '-exact-active',
    routes
})

router.beforeEach((to, from, next) => {
    if(to.name !== from.name && !to.hash) {
        window.scrollTo({top: 0, behavior: 'smooth'})
        next()
    }
})

export default router
