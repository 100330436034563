<template>
  <div class="navbar" v-if="$beet.isReady">
    <router-link :to="{ name: 'home' }" class="navbar__logo" @click="mobileMenuOpen = false"> </router-link>
    <div class="navbar__links" v-if="mq.mdPlus">
      <router-link :to="{ name: 'program' }" class="navbar__button">{{
        $beet.options.menu.program
      }}</router-link>


      <div
        @mouseover="isDropdownOpen = true"
        @mouseleave="isDropdownOpen = false"
      >
        <div class="navbar__button -dropdown">
          <span>{{ $beet.options.menu.info }}</span>
          <icon
            id="Accordeon"
            class="navbar__open"
            :class="{ '-reverse': isDropdownOpen }"
          />
        </div>
        <div class="navbar__dropdown" :class="{ '-open': isDropdownOpen }">
          <router-link :to="{ name: 'info' }" class="navbar__button -submenu">
            {{ $beet.options.menu.venueFaq }}
          </router-link>
          <router-link :to="{ name: 'prices' }" class="navbar__button -submenu">
            {{ $beet.options.menu.pricing }}
          </router-link>
        </div>
      </div>
      <router-link :to="{ name: 'submission' }" class="navbar__button -big">
      {{ $beet.options.menu.submission }}
    </router-link>
      <a v-if="$beet.options.menu.ticketsLink" :href="$beet.options.menu.ticketsLink" target="_blank" class="navbar__button -red">
        {{ $beet.options.menu.tickets }}
        <Icon id="ArrowDiagonal" class="navbar__button__icon" />
      </a>
    </div>

    <div class="navbar__buttons-mobile" v-else>
      <a v-if="$beet.options.menu.ticketsLink" :href="$beet.options.menu.ticketsLink" target="_blank" class="navbar__button -red -mobile">
        <Icon id="Ticket" class="navbar__button-icon" />
      </a>

      <button class="navbar__menu" @click="mobileMenuOpen = !mobileMenuOpen">
        <transition name="fade-fast" mode="out-in" :duration="100">
          <Icon v-if="mobileMenuOpen" :id="'Close'" :key="`icon-open`"/>
          <Icon v-else :id="'Menu'" :key="`icon-close`"/>
        </transition>

      </button>


    </div>
  </div>
  <div class="mobile-navbar" v-if="mobileMenuOpen && !mq.mdPlus">
    <h2 class="mobile-navbar__title">{{ $t("menu") }}</h2>
    <router-link @click="mobileMenuOpen = false" :to="{ name: 'program' }" class="mobile-navbar__link">
      {{ $beet.options.menu.program }}
    </router-link>
    <div
      v-if="false"
      class="mobile-navbar__link"
      :class="{'-open': isDropdownOpen}"
      @click="isDropdownOpen = !isDropdownOpen"
    >
      <span>{{ $beet.options.menu.info }}</span>
      <icon
        id="Accordeon"
        class="navbar__open"
        :class="{ '-reverse': isDropdownOpen }"
      />
    </div>
    <div class="mobile-navbar__submenu" :class="{ '-open': isDropdownOpen }">
      <router-link :to="{ name: 'info' }" @click="mobileMenuOpen = false" class="mobile-navbar__sub">
        {{ $beet.options.menu.venueFaq }}
      </router-link>
      <router-link :to="{ name: 'prices' }" @click="mobileMenuOpen = false" class="mobile-navbar__sub">
        {{ $beet.options.menu.pricing }}
      </router-link>
    </div>
    <router-link :to="{ name: 'submission' }" @click="mobileMenuOpen = false" class="mobile-navbar__link">
      {{ $beet.options.menu.submission }}
    </router-link>
    <button class="mobile-navbar__link" @click="scrollAndClose">
        {{ $t('contact') }}
    </button>
  </div>
</template>

<script>
export default {
  name: "SiteNav",
  inject: ["mq"],
  data() {
    return {
      isDropdownOpen: false,
      mobileMenuOpen: false,
    };
  },
  watch: {
    '$route'() {
      this.mobileMenuOpen = false
    }
  },
  methods: {
    scrollAndClose() {
      this.mobileMenuOpen = false;
      this.$emit('scrollBottom');
    }
  }
};
</script>
