<template>
  <component
    :is="tag"
    :class="[
      'raw-html',
      { '-pre-line': preserveBreaks, '-align-left': alignLeft },
    ]"
  >
    <slot />
  </component>
</template>

<script>
import sanitizeHtml from "sanitize-html";

export default {
  name: "RawHtml",
  props: {
    tag: { type: String, default: "div" },
    html: { type: [String, Object], default: Object },
    preserveBreaks: { type: Boolean, default: false },
    alignLeft: { type: Boolean, default: false },
  },
  computed: {
    htmlContent() {
      const { before = null, after = null } =
        typeof this.html === "string" ? { after: this.html } : this.html;
      return { afterbegin: before, beforeend: after };
    },
  },
  watch: {
    htmlContent: {
      immediate: true,
      handler: "renderHTML",
    },
  },
  methods: {
    renderHTML() {
      this.$nextTick(() => {
        const { default: slots = [] } = this.$slots;
        const nodes = slots.map(({ elm }) => elm.cloneNode(true));
        this.$el.innerHTML = "";
        nodes.forEach((node) => this.$el.appendChild(node));

        Object.keys(this.htmlContent).forEach((position) => {
          const content = this.htmlContent[position];
          if (!content) return;
          this.$el.insertAdjacentHTML(position, sanitizeHtml(content));

          Array.from(this.$el.querySelectorAll('a[href^="http"]')).forEach(
            (externalLink) => {
              externalLink.target = "_blank";
              externalLink.rel = "noopener";
            }
          );
        });
      });
    },
  },
};
</script>
