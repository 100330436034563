<template>
  <div class="popup__backdrop" @click="$emit('close')">
    <div class="popup" @click.stop>
      <div class="popup__header">
        <h2 class="popup__topic"> {{ section.time }} - {{ section.location }}</h2>
        <button class="popup__button" @click="$emit('close')"><Icon id="Close" /></button>
      </div>
      <h3 class="popup__title">{{ section.title }}</h3>
      <p class="popup__content">{{ section.description }}</p>
      <div class="popup__flex">
        <div class="popup__tag" v-if="section.type">
          <Icon id="Type" class="popup__icon"/>
          <span>{{ $beet.types[section.type].name }}</span>
        </div>
        <div class="popup__tag" v-for="(theme, indexThemes) in section.themes" :key="`theme-${indexThemes}`">
          <Icon id="Theme" class="popup__icon"/>
          <span>{{ $beet.themes[theme].name }}</span>
        </div>
      </div>
      <div class="popup__card" v-for="(presenter, index) in section.presenters" :key="`presenter-${index}`">
        <img class="popup__img" v-if="$beet.presenters[presenter].photo" :src="$beet.presenters[presenter].photo.lg" :alt="$beet.presenters[presenter].name">
        <div class="popup__bio">
          <p class="popup__author">
            <Icon id="Speaker" class="popup__icon"/>
            <span>{{ $beet.presenters[presenter].name }}</span>
          </p>
          <raw-html class="popup__content" :html="$beet.presenters[presenter].description"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from "@/components/global/Icon";
export default {
  name: "Popup",
  components: {Icon},
  props: {
    section: Object
  }
}
</script>

<style scoped>

</style>