<template>
  <div class="footer">
    <div class="footer__frame">
      <p>© 2022 Centre de recherche sur les infrastructures en béton. All rights reserved.</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer"
}
</script>
