<template>
  <div class="info" v-if="$page && $beet.isReady">
    <div class="header-img -no-pb" :style="`background-image: url('${$page.header.image.lg}');`">
      <div class="header-img__topic">{{ $page.header.title }}</div>
      <div class="header-img__flex">
        <div class="header-img__info">
          <div class="header-img__heading">{{ $page.header.subtitle }}</div>
          <div class="header-img__subtitle">{{ $beet.options.menu.dates }}</div>
        </div>
        <div class="header-img__links">
          <div class="header-img__link" @click="scrollMeTo('venue')">
            <span>{{ $page.venue.title }}</span>
            <Icon class="header-img__icon" id="Arrow" />
          </div>
          <div class="header-img__link" @click="scrollMeTo(mq.mdPlus ? 'venue' : 'transport')">
            <span>{{ $page.transport.title }}</span>
            <Icon class="header-img__icon" id="Arrow" />
          </div>
          <div class="header-img__link" @click="scrollMeTo('accomodations')">
            <span>{{ $page.accommodations.title }}</span>
            <Icon class="header-img__icon" id="Arrow" />
          </div>
          <div class="header-img__link" @click="scrollMeTo('restaurants')">
            <span>{{ $page.restaurants.title }}</span>
            <Icon class="header-img__icon" id="Arrow" />
          </div>
          <div class="header-img__link" @click="scrollMeTo('faq')">
            <span>{{ $page.faq.title }}</span>
            <Icon class="header-img__icon" id="Arrow" />
          </div>
        </div>
      </div>
    </div>

    <div class="home__wrap">
      <div class="venue" ref="venue">
        <div class="venue__topics">
          <div class="venue__topic">{{ $page.venue.title }}</div>
          <div class="venue__topic" v-if="mq.mdPlus">{{ $page.transport.title }}</div>
        </div>
        <div class="venue__img" :style="`background-image: url('${$page.venue.image.lg}');`"></div>
        <div class="venue__flex">
          <div class="venue__element">
            <h2 class="venue__title">{{ $page.venue.subtitle }}</h2>
            <div class="venue__flex -bottom">
              <div class="venue__element">
                <div class="venue__contact">
                  <Icon id="Address" class="venue__icon"/>
                  <span>{{ $page.venue.address }}</span>
                </div>
                <div class="venue__contact">
                  <Icon id="Email" class="venue__icon"/>
                  <a :href="`mailto:${$page.venue.email}`" class="venue__link">{{ $page.venue.email }}</a>
                </div>
                <div class="venue__contact">
                  <Icon id="Phone" class="venue__icon"/>
                  <span>{{ $page.venue.phone }}</span>
                </div>
              </div>
              <div class="venue__element">
                <a :href="$page.venue.link" target="_blank" class="venue__flex -button">
                  <button class="venue__button">
                    <Icon id="ArrowDiagonal"/>
                  </button>
                  <p>{{ $page.venue.linkText }}</p>
                </a>
              </div>
            </div>
            <!-- TODO reactive image -->
            <img src="@/assets/Photos/map.jpg" class="venue__map"/>
          </div>
          <div class="venue__element" ref="transport">
            <div class="venue__topics -mt" v-if="!mq.mdPlus">
              <div class="venue__topic">{{ $page.transport.title }}</div>
            </div>
            <div class="venue__card" v-for="(card, index) in $page.transport.methods" :key="`transport-${index}`">
              <h3 class="venue__card-title">{{ card.title }}</h3>
              <raw-html class="venue__card-content" :html="card.description"/>
            </div>
          </div>
        </div>
      </div>

      <div class="accomodations venue" ref="accomodations">
        <h2 class="accomodations__topic">{{ $page.accommodations.title }}</h2>
        <div class="venue__flex -reverse">
          <div class="venue__element -mt">
            <h3 class="venue__title">{{ $page.accommodations.subtitle }}</h3>
            <raw-html class="venue__margin" :html="$page.accommodations.description"/>
            <a :href="$page.accommodations.link" target="_blank" class="venue__flex -button">
              <button class="venue__button">
                <Icon id="ArrowDiagonal"/>
              </button>
              <p>{{ $page.accommodations.linkText }}</p>
            </a>
          </div>
          <div class="venue__element">
            <img :src="$page.accommodations.image.lg" :alt="$page.accommodations.subtitle" class="accomodations__img">
          </div>
        </div>
      </div>

      <div class="accomodations venue" ref="restaurants">
        <div class="accomodations__topics">
          <div v-if="mq.mdPlus" class="accomodations__empty"></div>
          <div class="accomodations__single">{{ $page.restaurants.title }}</div>
        </div>
        <div class="accomodations__flex">
          <div class="accomodations__element">
            <img :src="$page.restaurants.image.lg" :alt="$page.restaurants.title" class="accomodations__img">
          </div>
          <div class="accomodations__element -mt">
            <h2 class="venue__title">{{ $page.restaurants.subtitle }}</h2>
            <raw-html class="venue__margin" :html="$page.restaurants.description"/>
            <a :href="$page.restaurants.link" target="_blank" class="venue__flex -button">
              <button class="venue__button">
                <Icon id="ArrowDiagonal"/>
              </button>
              <p>{{ $page.restaurants.linkText }}</p>
            </a>
          </div>
        </div>
      </div>

      <div class="faq" ref="faq">
        <h2 class="faq__title">{{ $page.faq.title }}</h2>
        <div class="faq__card" @click="open(index)" v-for="(question, index) in $beet.faq" :key="`faq-${index}`">
          <h3 class="faq__question"><span>{{ question.question }}</span><Icon id="Accordeon" :class="{'-opened': opened === index}" class="faq__icon"/></h3>
          <DropDown :is-open="opened === index">
            <div class="faq__answer">
              <raw-html :html="question.answer"/>
            </div>
          </DropDown>
        </div>
      </div>
      <div class="contact-section">
        <ContactUs/>
      </div>
    </div>
    <Footer/>
  </div>
</template>

<script>
import Icon from "@/components/global/Icon";
import ContactUs from "@/components/ContactUs";
import Footer from "@/components/Footer";
export default {
  name: "Info",
  beetPage: 'venue',
  inject: ['mq'],
  components: {Footer, ContactUs, Icon},
  data () {
    return {
      opened: null,
    };
  },
  methods: {
    scrollMeTo (ref) {
      const padding = this.mq.mdPlus ? 200 : 100;
      window.scrollTo({ top: this.$refs[ref].offsetTop - padding, behavior: 'smooth' });

    },
    open (index) {
      if (this.opened === index) {
        this.opened = null;
      } else {
        this.opened = index;
      }
    }
  }
};
</script>
