<template>
  <div>
    404
  </div>
</template>

<script>

export default {
  name: 'NotFound'
}
</script>
