<template>
    <div>
        
    </div>
</template>

<script>
export default {
    name: 'Tickets'
}
</script>