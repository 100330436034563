export default {
    form: {
        subject: "Select a subject",
        name: "Full name",
        email: "Email address",
        company: "Company (optional)",
        message: "Message"
    },
    themes: "Themes",
    buttons: {
        more: "See more",
        less: "See less"
    },
    menu: "Menu",
    contact: "Contact us",
    team: "Our team",
    filters: "Filters",
    select: {
        type: "Select a type",
        theme: "Select a theme"
    },
    reset: "Reset",
    empty: "No results matching with current filters."
}
